import React from 'react'

function Service({header, img1, img2, txt, reversed}) {
      return (
    <div className='flex flex-col relative sm:justify-center items-center'>
      <section className='m-10 mb-8 flex justify-center items-center text-5xl'>{header}</section>
      <img src={img1} className='object-cover object-center w-full p-6 h-auto max-h-[50vh]' />
      <div className={reversed ? 'flex flex-col-reverse sm:flex-row-reverse justify-evenly items-center w-full sm:m-8' : 'flex flex-col-reverse sm:flex-row justify-evenly items-center w-full sm:m-8'}>
      <img src={img2} className='mt-4 sm:mt-0 max-h-[50vh] w-full sm:w-1/3 sm:flex object-cover object-center' />
        <p className='w-full p-4 sm:p-0 sm:w-1/2 sm:text-3xl text-center'>{txt}</p>
      </div>
    </div>
  )
}

export default Service